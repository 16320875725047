.modal {
  $this: &;
  display: flex;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  opacity: 1;

  transition: opacity 0.3s;

  width: 100%;
  height: 100vh;
  overflow: auto;

  padding: 16px;

  &__overlay {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(get-color(background-rgb), 0.85);
  }

  &__content {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    justify-content: flex-end;

    padding: 40px;
    padding-top: 46px;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    box-shadow: 0 0 10px rgba(black, 0.1);

    background: get-color(background);

    &--shift-up {
      margin-top: -64px;
    }

    &--gift {
      padding: 20px;
      width: 100%;
      border: 1px solid rgba(get-color(on-background-rgb),0.1);
      border-radius: Min($input-border-radius, 20px);

      @include media-query(sm) {
        padding: 24px 32px;
      }
    }

    &--large {
      max-width: 520px;
    }

    &--small {
      max-width: 360px;
    }
  }

  &__heading {
    margin-bottom: 28px;

    font-size: font-size-heading(16px);
    line-height: $line-height-base;

    &--center {
      text-align: center;
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    color: get-color(text-pale);
    cursor: pointer;

    svg {
      fill: none;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    @include media-query(sm) {
      position: absolute;
      transform: translateX(50%);
      top: 20px;
      right: 50px;
      transform: unset;
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked ~ .modal,
    input[type="checkbox"]:checked ~ .modal__background {
      display: block;
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    background-color: rgba(get-color(black),0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 18px;

    opacity: 0.4;

    transition: opacity $transition-time;

    background: none;

    color: get-color(text);

    &:hover {
      opacity: 1;
    }
  }

  &__cta {
    display: flex;

    & > * {
      flex: 1 1 auto;

      & + * {
        margin-left: 24px;
      }
    }
  }

  &__back {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    align-items: center;

    padding: 12px 16px;

    cursor: pointer;

    line-height: 24px;
    font-size: $font-size-base;
    color: #686868;

    &--mobile {
      @include media-query(md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-query(md) {
        display: block;
        right: 0;
        left: auto;
        padding: 24px;
      }
    }

    &-chevron {
      position: relative;
      top: -1px;
      margin-right: 10px;
    }
  }

  &--sm {
    display: none;

    @include media-query(sm) {
      display: block;
    }
  }

  &--password {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: none;
    width: 100%;
    height: 100%;
  }

  &__icon {
    margin-right: 7px;
  }

  &.visible {
    > #{$this}__background {
      display: block;
      pointer-events: auto;
    }
  }
}
