.color-swatch {
  $this: &;

  font-size: $font-size-base;

  &__input {
    display: none;

    &:checked {
      & + #{$this}__label {
        border-color: get-color(primary);

        #{$this}__color {
          width: 30px;
          height: 30px;
        }
      }
    }

    &.unavailable + #{$this}__label {
      &::before {
        content: '';

        position: absolute;
        z-index: 1;
        top: calc(50% - 1px);
        left: 0;

        transform: rotate(-45deg);

        border-top: 1px solid rgba(get-color(background-rgb), 0.2);
        border-bottom: 1px solid rgba(get-color(on-background-rgb), 0.2);

        height: 0;
        width: 100%;
      }
    }
  }

  &__label {
    display: block;
    position: relative;

    transition: border-color 0.2s;

    border: 3px solid transparent;
    border-radius: 14px;
    padding: 7px;
    box-shadow: none;
    width: 46px;
    height: 46px;

    cursor: pointer;

    text-align: center;
  }

  &__color {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    transition: width 0.2s, height 0.2s;

    border: 1px solid rgba(get-color(on-background-rgb), 0.15);
    border-radius: 16px;

    width: 40px;
    height: 40px;

    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.44);
  }
}
