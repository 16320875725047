.divider {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__line {
    flex: 1 1 auto;
    height: 1px;
    background-color: get-color(divider);
  }
}
