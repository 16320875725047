.inline-image {
  $this: &;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-left: 16px;
  }

  &__image {
    display: block;
    flex: 0 0 104px;

    &--large {
      flex-basis: 160px;
    }

    &--small {
      flex-basis: 80px;
    }
  }

  &__donation-image {
    position: relative;

    border-radius: $img-border-radius;
    padding-bottom: $img-ratio-padding;

    width: 100%;
    height: 0;

    background: get-color(background-pale);

    font-size: font-size(32px);
  }

  &__donation-image-icon {
    display: flex;

    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  &__content {
    flex: 1 1 auto;
    margin-left: 20px;

    @include media-query(sm) {
      margin-left: 30px;
    }
  }
  
  &__link {
    text-decoration: none;

    #{$this}__heading {
      &:hover {
        color: get-color(primary);
      }
    }
  }

  &__heading {
    @include font-heading();

    transition: color $transition-base;
    color: get-color(heading);
    font-size: font-size-heading(16px);
  }

  &__subheading {
    margin-top: 4px;
  }

  &__price {
    display: inline-block;
    margin-top: 4px;

    &--offer {
      color: rgba(get-color(on-background-rgb), .5);
      text-decoration: line-through;
      text-decoration-style: solid;
      margin-right: 5px;
    }
  }

  &__quantity {
    margin-top: 4px;
  }

  &__cta {
    display: inline-block;
    position: relative;
    margin-top: 10px;
  }
  
  &__cta-response {
    display: none;
    align-items: center;
    margin-top: 8px;
    font-size: $font-size-small;

    &.visible {
      display: flex;
    }

    &--error {
      color: get-color(error);
    }
  }

  &__cta-response-icon {
    margin-top: -1px;
    margin-right: 4px;
  }

  &--columns {
    #{$this}__quantity {
      flex: 0;
      flex-basis: 30px;
      margin-top: 0;
      text-align: right;
    }

    #{$this}__price {
      flex: 0;
      flex-basis: 70px;

      margin-top: 0;

      color: get-color(heading);
      text-align: right;
    }
  }
}
