.badge {
  display: inline-block;

  border-radius: $button-border-radius;
  padding: 4px 10px;

  background-color: get-color(on-background);

  color: get-color(background);
  font-size: font-size(12px);
  font-weight: $font-weight-base-bold;
  line-height: 1.5;
}
