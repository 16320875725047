@use "sass:math";

.container {
  $grid-columns: 12;

  display: block;

  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $container-width;
  
  @for $i from 1 through ($grid-columns - 1) {
    &--#{$i} {
      max-width: math.div($container-width, $grid-columns) * $i;
    }
  }
}
