.phone {
  $this: &;
  max-width: 65%;
  width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 130%;

  @media (max-width: 767px) {
    //padding-bottom: 148%;
  }

  &__object {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
    }

    video {
      width: 100%;
    }

    &--video {
      width: calc(100% - 12%);
      height: calc(100% - 12%);
      top: 6%;
      left: 6%;
      z-index: 0;
    }
  }
}