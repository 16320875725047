.rich-text {
  $this: &;
  text-align: left;

  &__header {
    margin-bottom: 20px;
  }

  &--article {
    #{$this}__inner {
      padding: 30px 0;
    }
  }
}
