.main {
  &__sections {
    & > * + * {
      padding-top: 64px;

      @include media-query(sm) {
        padding-top: 88px;
      }
    }

    &--xspace {
      padding-top: 48px;

      @include media-query(sm) {
        padding-top: 80px;
      }
    }

    &--space {
      padding-top: 24px;

      @include media-query(sm) {
        padding-top: 56px;
      }
    }
  }

  &__xspace {
    padding-top: 48px;
  }

  &__xxspace {
    padding-top: 40px;

    @include media-query(sm) {
      padding-top: 72px;
    }
  }
}
