.header {
  $this: &;

  position: relative;
  z-index: 10;

  &__sections {
    display: grid;

    align-items: center;
    gap: 24px;
    grid-template-columns:  1fr minmax(80px, $logo-max-width) 1fr;

    height: $header-height;

    @include media-query(sm) {
      grid-template-columns: minmax(max-content, $logo-max-width) 1fr minmax(max-content, $logo-max-width);
      height: $header-height-sm;
    }
  }

  &__section {
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    max-width: 100%;

    &--middle {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__logo {
    @include font-heading();

    text-align: center;
    font-size: font-size-heading(20px);

    @include media-query(sm) {
      text-align: left;
    }
  }

  &__logo-link {
    display: block;
    color: get-color(on-background);
    text-decoration: none;

    &:hover {
      color: get-color(on-background);
    }
  }

  &__logo-image {
    display: block;
    object-fit: contain;
    object-position: center center;

    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;

    width: 100%;
    max-width: $logo-max-width;
    max-height: $header-height;

    @include media-query(sm) {
      object-position: left center;
      margin: 0;
      max-height: $header-height-sm;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &--right-nav {
      margin-right: -16px;
    }
  }

  &__list-item {
    position: relative;
    flex: 0 0 auto;

    &:hover {
      #{$this}__dropdown {
        display: block;
      }
    }
  }

  &__link {
    display: flex;

    position: relative;

    justify-content: center;
    align-items: center;

    padding: 12px;

    color: get-color(header-link);
    font-size: font-size(14px);
    font-weight: $font-weight-base-bold;
    text-decoration: none;

    &:hover {
      color: get-color(header-link-hover);
    }

    &--icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 14px;

      &.active {
        #{$this}__link-icon {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
    }
  }

  &__link-icon {
    margin-left: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__icon {
    display: block;
    padding: 12px;
    color: get-color(header-link);

    &:hover {
      color: get-color(header-link-hover);
    }

    &--hamburger {
      padding-left: 0;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__dropdown {
    display: none;

    justify-content: center;

    position: absolute;
    z-index: 1;
    left: 12px;

    margin: 0;

    min-width: 120px;
    
    text-align: left;
  
    &--content {
      left: 7px;
      min-width: 70px;
      overflow: hidden;

      #{$this}__dropdown-inner {
        max-height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: var(--input-corner-radius);
          -webkit-box-shadow: inset 0 0 6px rgba(get-color(primary), 0.3);
          background-color: get-color(primary);
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(get-color(primary), 0.3);
          border-radius: var(--input-corner-radius);
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb:window-inactive {
          background-color: transparent;
        }
      }
    }
  }

  &__dropdown-inner {
    display: grid;
    overflow: hidden;

    gap: 12px;

    border: 1px solid rgba(get-color(on-background-rgb), 0.1);
    border-radius: $input-border-radius;
    padding: 11px 15px;
    
    background: get-color(background);
  }

  &__dropdown-link {
    color: get-color(header-link);
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: get-color(header-link-hover);
    }
  }

  &--main {
    #{$this}__link,
    #{$this}__logo-link {
      color: get-color(background);
    }

    .button {
      &--outline {
        margin-left: 10px;
        color: get-color(background);
        border-color:get-color(background);
      }
    }
  }

  &--blog {
    border: 1px solid rgba(var(--color-on-background-rgb), 0.2);
    #{$this}__link{
      color: get-color(on-background);
    }

    #{$this}__logo-link {
      color: get-color(primary);
    }
  }
}
