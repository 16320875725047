@charset "UTF-8";

// - - - - - - - - - - - - - - - - - - - - - - - -
// Core Tools
// - - - - - - - - - - - - - - - - - - - - - - - -

@import 'styles/tools/px-to-rem';
@import 'styles/tools/font-size';
@import 'styles/tools/get-color-alpha';

// - - - - - - - - - - - - - - - - - - - - - - - -
// Variables
// - - - - - - - - - - - - - - - - - - - - - - - -

// Breakpoints/layout
$breakpoints: (
  sm: 640px,
  md: 1024px,
  lg: 1440px,
);

$container-width: 1400px;

$gutter: 16px;
$gutter-sm: 32px;

// - - - - - - - - - - - - - - - - - - - - - - - -
// Typography
// - - - - - - - - - - - - - - - - - - - - - - - -

$font-family-base: var(--font-family-base);
$font-family-heading: var(--font-family-heading);

$font-weight-base: var(--font-weight-base);
$font-weight-base-bold: var(--font-weight-base-bold);
$font-weight-heading: var(--font-weight-heading);

$font-style-base: var(--font-style-base);
$font-style-heading: var(--font-style-heading);

$line-height-base: 1.5;
$line-height-heading: 1.2;
$line-height-high: 1.8;

$text-transform-base: var(--text-transform-base);
$text-transform-heading: var(--text-transform-heading);

$letter-spacing-heading: normal;

$font-size-scale-base: 1;
$font-size-scale-heading: 1;

$font-size-base: font-size(16px);
$font-size-small: font-size(14px);
$font-size-medium: font-size(18px);

$font-size-headings: (
  h1: 2.5rem,
  h2: 2rem,
  h3: 1.5rem,
  h4: 1.25rem,
  h5: 1rem,
  h6: 0.875rem,
);

// - - - - - - - - - - - - - - - - - - - - - - - -
// Colors
// - - - - - - - - - - - - - - - - - - - - - - - -

$color-primary: var(--color-primary);
$color-primary-rgb: var(--color-primary-rgb);
$color-on-primary: var(--color-on-primary);
$color-on-primary-rgb: var(--color-on-primary-rgb);
$color-background: var(--color-background);
$color-background-rgb: var(--color-background-rgb);
$color-on-background: var(--color-on-background);
$color-on-background-rgb: var(--color-on-background-rgb);
$color-product-image-background: var(--color-product-image-background);

$color-border: rgba($color-on-background-rgb, .1);

$color-input: $color-on-background;
$color-error: #f44336;

$color-background-brightness: var(--color-background-brightness);
$pale-background-alpha: get-color-alpha(0.04, 0.1, $color-background-brightness);

$colors: (
  primary: $color-primary,
  primary-rgb: $color-primary-rgb,
  background: $color-background,
  background-rgb: $color-background-rgb,
  background-pale: rgba($color-on-background-rgb, $pale-background-alpha),
  on-primary: $color-on-primary,
  on-background: $color-on-background,
  on-background-rgb: $color-on-background-rgb,
  text: $color-on-background,
  text-pale: rgba($color-on-background-rgb, .6),
  link: $color-on-background,
  link-hover: rgba($color-on-background-rgb, .8),
  white: #fff,
  black: #000,
  error: $color-error,
  heading: $color-on-background,
  header-background: $color-background,
  header-link: $color-on-background,
  header-link-hover: rgba($color-on-background-rgb, .8),
  mobile-menu-link: $color-on-background,
  mobile-menu-link-hover: $color-on-background,
  footer-text: $color-on-primary,
  footer-text-pale: rgba($color-on-primary-rgb, .4),
  footer-link: rgba($color-on-primary-rgb, .6),
  footer-link-hover: rgba($color-on-primary-rgb, 1),
  live-badge-text: #fff,
  live-badge-background: #ff0000,
  divider: $color-border,
  image-background: #fff,
  image-badge-text: #666,
  image-badge-background: #eaeaea,
  input-text: $color-input,
  input-background: rgba($color-on-background-rgb, .1),
  input-border: rgba($color-on-background-rgb, .3),
  input-border-hover: rgba($color-on-background-rgb, .4),
  input-border-focus: rgba($color-on-background-rgb, .6),
  input-disabled-text: #d3d3d3,
  input-disabled-background: #f7f7f7,
  input-label: rgba($color-on-background-rgb, .6),
  input-radio: $color-input,
  input-radio-selected: $color-background,
  input-radio-background: $color-background,
  input-radio-background-selected: $color-on-background,
  input-radio-background-hover: rgba(#000, .03),
  input-radio-border-selected: $color-on-background,
  input-radio-border-hover: rgba($color-on-background-rgb, .3),
  select-option: $color-background,
  button-primary-text: $color-on-primary,
  button-primary: $color-primary,
  button-outline-text: $color-on-background,
  button-outline: rgba($color-on-background-rgb, .3),
  button-disabled-text: rgba($color-on-background-rgb, .6),
  button-disabled: rgba($color-on-background-rgb, $pale-background-alpha),
  product-image-background: $color-product-image-background
);

// - - - - - - - - - - - - - - - - - - - - - - - -
// Other
// - - - - - - - - - - - - - - - - - - - - - - - -

// Header
$header-height: 48px;
$header-height-sm: 80px;

// Logo
$logo-max-width: var(--logo-width);

// Images
$img-border-radius: var(--image-corner-radius);
$img-ratio-padding: var(--image-ratio-padding);

$gallery-img-ratio-padding: $img-ratio-padding;

// Buttons
$button-border-radius: var(--button-corner-radius);

// Inputs
$input-border-width: 2px;
$input-border-radius: var(--input-corner-radius);
$input-border: $input-border-width solid map-get($colors, 'input-border');
$text-input-border: 1px solid transparent;
$input-height: 56px;
$input-horizontal-padding: 16px;
$input-icon-width: 22px;
$input-font-family: $font-family-base;
$input-font-size: font-size(16px);
$input-line-height: 1.5;

// Divider
$divider-size: 1px;
$divider-style: solid;
$divider-border: $divider-size $divider-style map-get($colors, 'divider');

// Background clipping
$clip-size: 120px;

// Transition
$transition-time: 150ms;
$transition-base: $transition-time;
$transition-all: all 150ms ease;

// - - - - - - - - - - - - - - - - - - - - - - - -
// Imports
// - - - - - - - - - - - - - - - - - - - - - - - -

// Tools
@import 'styles/tools/autofill-fix';
@import 'styles/tools/get-breakpoint';
@import 'styles/tools/get-color';
@import 'styles/tools/media-query';

// Core
@import 'styles/core/generic';
@import 'styles/core/typography';

// Layout
@import 'styles/layout/container';
@import 'styles/layout/edge-mobile';
@import 'styles/layout/grid';
@import 'styles/layout/list';
@import 'styles/layout/wrapper';

// Inputs
@import 'styles/components/inputs/color-swatch';
@import 'styles/components/inputs/input-field';
@import 'styles/components/inputs/radio';
@import 'styles/components/inputs/radio-field';
@import 'styles/components/inputs/radio-button';
@import 'styles/components/inputs/checkbox';
@import 'styles/components/inputs/checkbox-field';
@import 'styles/components/inputs/checkbox-button';
@import 'styles/components/inputs/range';
@import 'styles/components/inputs/select-field';

// Components
@import 'styles/components/accordion';
@import 'styles/components/alert';
//@import 'styles/components/announcement-bar';
@import 'styles/components/background-mark';
@import 'styles/components/badge';
@import 'styles/components/body';
@import 'styles/components/breadcrumbs';
@import 'styles/components/button';
@import 'styles/components/collection';
@import 'styles/components/collection-template';
@import 'styles/components/countdown';
@import 'styles/components/divider';
@import 'styles/components/drawer';
@import 'styles/components/footer';
@import 'styles/components/form';
//@import 'styles/components/gallery';
//@import 'styles/components/gallery-thumbs';
@import 'styles/components/header';
@import 'styles/components/hero';
@import 'styles/components/html-formatter';
@import 'styles/components/ie11';
@import 'styles/components/image';
@import 'styles/components/image-section';
@import 'styles/components/image-with-text';
@import 'styles/components/inline-image';
@import 'styles/components/instagram-feed';
@import 'styles/components/link-list';
@import 'styles/components/main';
@import 'styles/components/mobile-menu';
@import 'styles/components/modal';
//@import 'styles/components/newsletter';
//@import 'styles/components/newsletter-form';
@import 'styles/components/order-success';
@import 'styles/components/page';
@import 'styles/components/pagination';
@import 'styles/components/recent-videos';
@import 'styles/components/rich-text';
@import 'styles/components/section-background';
@import 'styles/components/section-header';
//@import 'styles/components/share-button';
//@import 'styles/components/social-share';
@import 'styles/components/spinner';
@import 'styles/components/static-page';
@import 'styles/components/table';
@import 'styles/components/template-404';
@import 'styles/components/text-logo';
@import 'styles/components/tiers';
//@import 'styles/components/tiers-modal';
@import 'styles/components/tile';
//@import 'styles/components/thank-you';
//@import 'styles/components/video';
//@import 'styles/components/video-tile';
@import 'styles/components/post-tile';
@import 'styles/components/phone';
@import 'styles/components/posts';
@import 'styles/components/article';
@import 'styles/components/markdown';

// Utilities
@import 'styles/utilities/hidden';
@import 'styles/utilities/visible';
