.article {
  $this: &;

  .section-header__heading {
    font-size: font-size(35px);

    @include media-query(sm) {
      font-size: font-size(48px);
    }
  }
}