.section-background {
  $this: &;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &__image,
  &__video,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__video {
    object-fit: cover;
  }

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
