.tile {
  $this: &;

  display: block;
  text-decoration: none;

  &:hover {
    #{$this}__heading {
      color: get-color(primary);
    }

    #{$this}__image {
      &--2 {
        opacity: 1;
      }

      &--1 {
        opacity: 0;
      }
    }
  }

  .image {
    background-color: get-color(white);
  }

  &--show,
  &--post {
    padding: 24px;
    border: 1px solid rgba(get-color(on-background-rgb), 0.5);
    border-radius: $img-border-radius;

    #{$this}__heading {
      margin-top: 15px;
      font-size: font-size-heading(18px);
    }

    #{$this}__price {
      @include media-query(sm) {
        font-size: font-size(16px);
      }
    }

    #{$this}__description {
      padding: 0;
    }
  }

  &--no-pointer {
    cursor: initial;
  }

  &--posts {
    margin-top: 8px;

    #{$this}__values {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include media-query(md) {
        flex-direction: row;
      }
    }

    #{$this}__value {
      color: get-color(background);
      padding: 4px;
    }
  }

  &__description,
  &__info {
    margin-top: 16px;
    padding: 0 5px;

    @include media-query(sm) {
      margin-top: 20px;
    }

    &--center {
      text-align: center;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__heading {
    @include font-heading();

    transition: color $transition-base;

    font-size: font-size-heading(16px);
  }

  &__price {
    display: inline-block;
    color: get-color(text);

    &--offer {
      color: rgba(get-color(on-background-rgb), .5);
      text-decoration: line-through;
      text-decoration-style: solid;
      margin-right: 5px;
    }
  }

  &__meta {
    color: get-color(on-background);
  }

  &__price,
  &__meta {
    margin-top: 4px;

    @include media-query(sm) {
      margin-top: 8px;
    }
  }

  &__image {
    border-radius: $img-border-radius;

    &--1 {
      position: relative;
      opacity: 1;
    }

    &--2 {
      position: absolute;
      opacity: 0;
    }
  }

  &--zoom {
    #{$this}__image {
      img {
        transition: transform 0.4s, opacity 0.4s;
      }
    }
    &:hover {
      #{$this}__image {
        img {
          transform: scale3d(1.04, 1.04, 1);
        }
      }
    }
  }
}
