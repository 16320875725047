.checkbox-button {
  $this: &;

  position: relative;

  height: $input-height;

  color: get-color(input-text);
  font-size: $input-font-size;
  font-family: $input-font-family;

  &__input {
    display: none;

    &:checked + #{$this}__label {
      border-color: get-color(primary);
      color: get-color(button-primary-text);
    }

    &:disabled + #{$this}__label,
    &.unavailable + #{$this}__label {
      background-color: get-color(input-disabled-background);
      color: get-color(input-disabled-text);
    }
  }

  &__label {
    display: flex;

    align-items: center;
    justify-content: center;

    position: relative;

    transition: background-color $transition-time, border-color $transition-time,
      color $transition-time;

    border: $input-border;
    border-radius: $button-border-radius;

    padding: 0 $input-horizontal-padding;

    width: 100%;
    height: 100%;

    cursor: pointer;

    text-align: center;
    font-weight: $font-weight-base-bold;
    line-height: $line-height-base;

    &:hover {
      border-color: get-color(primary);
    }
  }
}
