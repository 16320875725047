.post-tile {
  $this: &;

  &--large {
    .tile {
      display: block;
      gap: $gutter;

      @include media-query(sm) {
        display: grid;
        grid-template-columns: 0.6fr 1.4fr;
        gap: $gutter-sm;
      }

      &__heading {
        margin-top: 15px;
        font-size: 1.125rem;
        @include media-query(sm) {
          font-size: font-size(45px);
        }
      }

      &__info {
        text-align: center;
        @include media-query(sm) {
          text-align: left;
        }
      }
    }
  }
}